import { SideDrawer } from '../../layout/SideDrawer';
import { ReviewCard } from '../../cards/Review';
import { createDrawerTitle } from '../../Reviews/DrawerContent';

import type { ProductReview } from '.';

type DrawerContentProps = {
  isOpen: boolean;
  productReview: ProductReview;
  toggleDrawer: () => void;
};

export const DrawerContent = ({ isOpen, toggleDrawer, productReview }: DrawerContentProps) => (
  <SideDrawer
    isOpen={isOpen}
    drawerTitle={() => createDrawerTitle()}
    hideDrawer={toggleDrawer}
    headerAlign="sticky"
  >
    {productReview && (
      <ReviewCard
        rating={productReview.review.rating}
        dateCreated={productReview.review.dateCreated}
        title={productReview.review.title}
        content={productReview.review.content}
        reviewerName={productReview.review.reviewerName}
        product={productReview.product}
      />
    )}
  </SideDrawer>
);
