import { getPrismicLinkPath } from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';
import type { RichTextField } from '@prismicio/types';
import { PrismicImage } from '../../types';

export const pushInlinePromo = (
  slice: {
    primary: {
      has_inline_promo: boolean;
      promo_row_index: number;
      promo_html_override: string;
      promo_title: string;
      promo_body: RichTextField[] | string;
      promo_cta_text: string;
      promo_url: any;
      promo_image: PrismicImage;
      promo_mobile_image: PrismicImage;
    };
  },
  embeds: any[]
) => {
  if (!slice.primary?.has_inline_promo) return null;

  embeds.push({
    type: 'InlinePromo',
    props: {
      title: slice.primary.promo_title,
      body: slice.primary.promo_body,
      ctaText: slice.primary.promo_cta_text,
      url: getPrismicLinkPath(slice.primary.promo_url),
      htmlOverride: slice.primary.promo_html_override,
      image: slice.primary.promo_image,
      mobile_image: slice.primary.promo_mobile_image,
      variation: slice.primary.promo_html_override ? 'htmlEmbed' : 'default',
    },
    pos: {
      rowPosition: slice.primary?.promo_row_index,
      column: { start: 1, end: -1 },
    },
  });
};
