import { Product } from '@virginexperiencedays/products/src/types';

export const pushFeaturedProducts = (
  slice: {
    prismicItems: {
      product_id: number;
      row_index: number;
      mobile_row_index: number;
      column_index: number;
      title_override: string;
      media_override: any;
    }[];
    items: Product[];
  },
  embeds: any[]
) => {
  const { prismicItems, items } = slice;

  if (!prismicItems || !items || !embeds) return null;

  prismicItems.forEach((prismicItem) => {
    if (prismicItem.product_id) {
      const productData = items.find((item) => String(prismicItem.product_id) === String(item.id));

      if (productData)
        embeds.push({
          type: 'FeaturedProduct',
          props: {
            id: productData.id,
            title: prismicItem.title_override || productData.title,
            rrp: productData.price?.rrp,
            displayPrice: productData.price?.displayPrice,
            src: prismicItem.media_override?.url || productData.media?.mainImage?.url,
            href: productData.url,
            contentList: [productData.description],
            aspectRatio: {
              mobile: '16/9',
              largeMobile: '16/9',
              tablet: '2/1',
            },
            promocode: productData.promocode,
            sku: productData.sku,
          },
          pos: {
            rowPosition: prismicItem.row_index,
            column: { start: prismicItem.column_index, end: null },
            mobileRowPosition: prismicItem.mobile_row_index,
          },
        });
    }
  });
};
