import { cn } from '@virginexperiencedays/tailwind';

import { DisplayHeading } from '../../typography/DisplayHeading';
import { BodyText } from '../../typography/BodyText';
import { Divider } from '../../layout/Divider';

import { StarRating } from '../../StarRating';
import { ProductStub } from './ProductStub';
import { ReviewCardReply } from './ReviewCardReply';

type Product = {
  imgUrl: string;
  linkUrl: string;
  imgAlt: string;
  name: string;
};

export type ReviewCardProps = {
  className?: string;
  rating: number;
  dateCreated: string;
  title: string;
  content: string;
  reviewerName: string;
  reply?: string;
  product?: Product;
};

export const ReviewCard = ({
  className = null,
  rating,
  dateCreated,
  title,
  content,
  reviewerName,
  reply,
  product,
}: ReviewCardProps) => {
  const date = new Date(dateCreated).toLocaleDateString('en-GB');

  return (
    <div className={cn('mt-6', className)}>
      <div className="flex flex-col items-start justify-start" data-testid="review-card">
        <span className="mb-4 mt-2 flex w-full items-center justify-between">
          <StarRating rating={rating} data-testid="star-rating" minDisplay={null} />
          <BodyText
            className="text-neutral-faded text-xs leading-none"
            data-testid="review-date"
            size="small"
          >
            {date}
          </BodyText>
        </span>

        <DisplayHeading className="text-neutral pb-2 leading-snug" size="3">
          &ldquo;{title}&rdquo;
        </DisplayHeading>
        <BodyText className="text-neutral" data-testid="review-body">
          {content}
        </BodyText>
        <BodyText
          className="text-neutral pt-4 text-xs font-semibold leading-none"
          data-testid={'review-author'}
        >
          {reviewerName}
        </BodyText>
      </div>
      {product && <ProductStub {...product} />}
      {reply && <ReviewCardReply content={reply} />}
      <Divider className="border-grey-200 mb-4 mt-6" variant="grey" />
    </div>
  );
};
