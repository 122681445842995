import { useState, useRef, useEffect } from 'react';

import type { Review } from '@virginexperiencedays/products';

import { DisplayHeading } from '../../typography/DisplayHeading';
import { BodyText } from '../../typography/BodyText';
import { StarRating } from '../../StarRating';
import { Button } from '../../layout/Button';
import { ProductStub } from './ProductStub';

type CategoryReviewCardType = Review & {
  productImgUrl: string;
  productLinkUrl: string;
  productImgAlt: string;
  productName: string;
  onReadMoreClick: () => void;
};

const IconWrapper = ({ children, ...rest }) => (
  <div className="mr-1" {...rest}>
    {children}
  </div>
);

export const CategoryReviewCard = ({
  dateCreated,
  rating,
  title,
  content,
  reviewerName,
  productImgUrl,
  productLinkUrl,
  productImgAlt,
  productName,
  onReadMoreClick,
}: CategoryReviewCardType) => {
  const [isClamped, setIsClamped] = useState(false);
  const contentRef = useRef(null);

  /**
   * Initialize formattedDateCreated always in a single timezone (Europe/London
   * IANA timezone, since that's where most of our users are, to be safe), so
   * server and client don't get mismatches in HTML content even if both are in
   * different timezones.
   */
  const londonDateCreated = new Date(dateCreated).toLocaleDateString('en-GB', {
    timeZone: 'Europe/London',
  });
  const [formattedDateCreated, setFormattedDateCreated] = useState(londonDateCreated);

  /**
   * Run a client-side update to formattedDateCreated to match local time. This
   * is done in a useEffect, so it occurs *after* hydration -- no server-client
   * mismatches should occur.
   */
  useEffect(() => {
    const localDateCreated = new Date(dateCreated).toLocaleDateString('en-GB');
    if (localDateCreated !== formattedDateCreated) {
      setFormattedDateCreated(localDateCreated);
    }
  }, [dateCreated]);

  //@TODO: We could probs just calculate how many characters fits into 3 lines on average with min and max values, and just render it based on the content length instead of using side effects
  useEffect(() => {
    /* istanbul ignore next */
    if (typeof window === 'undefined' || !contentRef?.current) return;

    const handleClamping = () => {
      setIsClamped(contentRef.current.scrollHeight > contentRef.current.clientHeight);
    };

    handleClamping();
    window.addEventListener('resize', handleClamping);
    return () => window.removeEventListener('resize', handleClamping);
  }, []);

  return (
    <div
      className="bg-background-elevation-overlay flex h-full flex-col rounded-lg px-4 py-6 shadow-md"
      data-testid="category-product-review-card"
    >
      <StarRating rating={rating} IconWrapper={IconWrapper} />
      <DisplayHeading
        className="text-neutral my-4 line-clamp-1 text-base leading-snug"
        data-testid="cpr-title"
        size="4"
        as="h3"
      >
        “{title}”
      </DisplayHeading>
      <div className="flex-1" data-testid="cpr-body">
        <div
          className="text-neutral relative line-clamp-5 max-h-[105px]"
          data-testid="cpr-content"
          ref={contentRef}
        >
          <BodyText data-testid="cpr-content-text" size="small">
            {content}
          </BodyText>
        </div>
        {isClamped && (
          <Button
            className="text-neutral my-4 bg-transparent p-0 font-normal leading-normal underline"
            data-testid="cpr-read-more"
            variant="invisible"
            onClick={onReadMoreClick}
          >
            Read more
          </Button>
        )}
        <div className="mt-4">
          <BodyText
            className="text-neutral mb-2 text-xs font-semibold leading-none"
            data-testid="cpr-author"
            size="small"
          >
            {reviewerName}
          </BodyText>
          <BodyText
            className="text-neutral text-xs leading-none"
            data-testid="cpr-date"
            size="small"
          >
            {formattedDateCreated}
          </BodyText>
        </div>
      </div>
      <ProductStub
        imgUrl={productImgUrl}
        linkUrl={productLinkUrl}
        imgAlt={productImgAlt}
        name={productName}
      />
    </div>
  );
};
