// Constants
import { MINIMUM_TOTAL_CUSTOMER_REVIEW } from '../../constants';
// Components
import { CategoryReviews as Component } from '@virginexperiencedays/components-v2/src/carousels/CategoryReviews';
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
// Utils
import { stripExperiencesText } from '../../utils/overrides';
// Types
import type { Product, Review } from '@virginexperiencedays/products/src/types';
import type { PrismicContext } from '../../types/context';

/**
 * NOTE: if needed in future to change algolia index,
 * add a slice.primary.preset_type field in this prismic slice
 * (Refer to AlgoliaSliceAdapter@getProductsFromSlice)
 */
type CategoryReviewsProps = {
  slice: {
    primary: {
      title: string;
      description: string;
    };
    items: (Product & {
      recentReview?: Review;
    })[];
  };
  context: PrismicContext;
};

const CategoryReviews = ({ slice, context }: CategoryReviewsProps) => {
  const { pageTitle, slug: pageSlug, isFaceted } = context;
  const {
    primary: { title, description },
    items,
  } = slice;

  if (!items?.length || !items.filter((item) => item.title).length) return null;

  const productReviews =
    items?.map(
      ({
        sku,
        slug,
        title: name,
        media: {
          mainImage: { url: imgUrl },
        },
        reviews,
        recentReview,
      }) => {
        return {
          product: {
            name,
            imgUrl,
            imgAlt: name,
            linkUrl: `/product/${slug}`,
            reviews,
          },
          review: {
            title: recentReview?.title,
            content: recentReview?.content,
            rating: recentReview?.rating || reviews.averageRating,
            reviewerName: recentReview?.reviewerName,
            sku,
            dateCreated: recentReview?.dateCreated,
          },
        };
      }
    ) || [];

  const name = stripExperiencesText(pageTitle);

  if (isFaceted || productReviews.length < MINIMUM_TOTAL_CUSTOMER_REVIEW) return null;

  return (
    <div className="bg-background-page-faded px-0 pb-20 pt-10">
      <Container>
        <Component
          id={`${pageSlug}-reviews`}
          title={title || 'Customer Reviews'}
          description={description || `What customers are saying about our ${name} experiences`}
          productReviews={productReviews}
        />
      </Container>
    </div>
  );
};

export default CategoryReviews;
