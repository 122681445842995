import { useEffect, useState } from 'react';

import { cn } from '@virginexperiencedays/tailwind';
import { useScrollLock } from '@virginexperiencedays/hooks';
import type { Review } from '@virginexperiencedays/products';

import { CategoryReviewCard } from '../../cards/Review/CategoryReviewCard';
import { type DisplayHeadingProps } from '../../typography/DisplayHeading';
import { Carousel } from '../../layout/Carousel';
import { useDrawer } from '../../layout/SideDrawer/useDrawer';
import { DrawerContent } from './DrawerContent';

type Product = {
  imgUrl: string;
  linkUrl: string;
  imgAlt: string;
  name: string;
  reviews: {
    averageRating: number;
  };
};

export type ProductReview = {
  product: Product;
  review: Review;
};

type ReviewsProps = {
  title: string;
  description: string;
  /**
   * ID is used for link from review summary
   */
  id: string;
  productReviews: ProductReview[];
  className?: string;
  dataTestId?: string;
  titleSize?: DisplayHeadingProps['size'];
};

export const CategoryReviews = ({
  className,
  id,
  title,
  titleSize,
  description,
  productReviews,
  dataTestId = 'category-product-review-carousel',
}: ReviewsProps) => {
  const [currentReview, setCurrentReview] = useState<ProductReview>();
  const { isOpen: isDrawerOpen, toggleDrawer } = useDrawer();
  const { lockScroll, unlockScroll } = useScrollLock();

  useEffect(() => {
    isDrawerOpen ? lockScroll() : unlockScroll();
  }, [isDrawerOpen, lockScroll, unlockScroll]);

  if (!productReviews?.length) return null;

  return (
    <div className={cn('block', className)} data-testid={dataTestId} id={id}>
      <Carousel
        trackClassName="pb-2 children:max-w-none"
        title={title}
        titleSize={titleSize || '2'}
        description={description}
        perPage={{
          tablet: 2,
          desktop: 3,
          wide: 4,
        }}
      >
        {productReviews.map(({ review, product }) => (
          <CategoryReviewCard
            key={`CategoryReviews-${review.title}`}
            {...review}
            onReadMoreClick={() => {
              setCurrentReview({ review, product });
              toggleDrawer();
            }}
            productImgUrl={product.imgUrl}
            productLinkUrl={product.linkUrl}
            productImgAlt={product.imgAlt}
            productName={product.name}
          />
        ))}
      </Carousel>
      <DrawerContent
        productReview={currentReview}
        toggleDrawer={toggleDrawer}
        isOpen={isDrawerOpen}
      />
    </div>
  );
};
