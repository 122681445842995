import type { IconProps as Props } from './types';

export const IconReplyArrow = ({
  className,
  width = '1em',
  height = '1em',
  color = 'var(--text-brand)',
  ...rest
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      stroke={color}
      strokeWidth="2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      fill="none"
      {...rest}
    >
      <path
        d="M13 8.33301L6.33333 8.33301C4.91885 8.33301 3.56229 7.7711 2.5621 6.77091C1.5619 5.77072 1 4.41416 1 2.99967L1 1.66634M13 8.33301L9 4.33301M13 8.33301L9 12.333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
