import { useRef } from 'react';
// Components
// TODO: update to v2 version of these web components when available
import useFeatureFlags from '@virginexperiencedays/feature-flags';

import { ProductGrid } from '@virginexperiencedays/web/components/pages/search/ProductGrid';
import { QuickFilterBar } from '@virginexperiencedays/web/components/pages/search/Filters/QuickFilterBar';
import { ListingControls } from '@virginexperiencedays/web/components/pages/search/ListingControls';
import { Button } from '@virginexperiencedays/components-v2/src/layout/Button';
import { Container } from '@virginexperiencedays/components-v2/src/layout/Container';
// Utils
import { useRouterPush } from '@virginexperiencedays/web/utils/routing/useRouterPush';
import { pushFeaturedProducts } from './pushFeaturedProducts';
import { pushInlinePromo } from './pushInlinePromo';
import { slugToName } from '@virginexperiencedays/search/utils/category';
import { LCP_PREFETCH_SLICE_COUNT } from '../../constants';
// Types
import type { EmbedType } from '@virginexperiencedays/web/components/pages/search/ProductGrid/GridEmbed';
import type { Product } from '@virginexperiencedays/products/src/types';
import type { RichTextField } from '@prismicio/types';
import type { PrismicContext } from '../../types/context';
import type { PrismicImage } from '../../types';

type ProductGridProps = {
  slice: {
    primary: {
      display: 'display_product_grid' | 'display_view_all_button';
      featured_product_id: number;
      featured_title_override: string;
      featured_media_override: any;
      featured_row_index: string;
      featured_column_index: string;
      has_inline_promo: boolean;
      promo_row_index: number;
      promo_html_override: string;
      promo_title: string;
      promo_body: RichTextField[] | string;
      promo_cta_text: string;
      promo_url: any;
      promo_image: PrismicImage;
      promo_mobile_image: PrismicImage;
    };
    prismicItems: {
      product_id: number;
      row_index: number;
      column_index: number;
      title_override: string;
      media_override: any;
      mobile_row_index: number;
    }[];
    items: Product[];
    position?: number;
  };
  context: PrismicContext;
};

const ProductGridSlice = ({ slice, context }: ProductGridProps) => {
  const { refine } = useRouterPush();
  const refElementToScroll = useRef();
  const [isEnabled] = useFeatureFlags();

  const { primary, position } = slice;
  const {
    url,
    categoryType,
    page,
    slug,
    hide: forceDisplay,
    isFaceted,
    overridesSlugInfo,
    pageTitle,
    hideEmbeds,
    showProductGrid,
  } = context;
  const displayGrid = primary?.display !== 'display_view_all_button';
  const normalizedSlug = Array.isArray(slug) ? slug[slug.length - 1] : slug;
  const slugName = slugToName(normalizedSlug);

  // forceDisplay - when page is faceted we want to hide other modules and force product grid to be expanded

  const embeds = [] as EmbedType[];

  pushInlinePromo(slice, embeds);
  pushFeaturedProducts(slice, embeds);

  return (
    <>
      {displayGrid || page || forceDisplay || isFaceted || showProductGrid ? (
        <section ref={refElementToScroll}>
          {isEnabled('FF_new_filters') ? (
            <QuickFilterBar
              categoryType={categoryType}
              serverSlug={slug as string[]}
              containerRef={refElementToScroll}
              overridesSlugInfo={overridesSlugInfo}
              title={`All ${pageTitle} Experiences`}
              refElementToScroll={refElementToScroll}
            />
          ) : (
            <Container>
              <ListingControls categoryType={categoryType} />
            </Container>
          )}
          <Container>
            <ProductGrid
              url={url}
              categoryType={categoryType}
              embeds={embeds}
              refElementToScroll={refElementToScroll}
              hasEmbeds={!isFaceted && !page && !hideEmbeds}
              slugs={Array.isArray(slug) ? slug : [slug]}
              isFaceted={isFaceted}
              priority={position <= LCP_PREFETCH_SLICE_COUNT}
            />
          </Container>
        </section>
      ) : (
        <Container>
          <div className="flex justify-center">
            <Button
              variant="primary"
              onClick={() => {
                refine({
                  query: {
                    hide: 'false',
                  },
                });
              }}
            >
              {`View all ${slugName} Experiences`}
            </Button>
          </div>
        </Container>
      )}
    </>
  );
};

export default ProductGridSlice;
