import { BodyText } from '../../typography/BodyText';
import { IconReplyArrow } from '../../icons/IconReplyArrow';

export type ReviewReplyProps = {
  content: string;
};

export const ReviewCardReply = ({ content }: ReviewReplyProps) => {
  return (
    <div
      className="bg-background-primary-faded mt-6 flex flex-col items-start justify-start overflow-hidden px-4 pb-8 pt-6"
      data-testid="review-card-reply"
    >
      <div className="mb-4 flex w-full items-center">
        <IconReplyArrow className="mr-2 min-w-[1rem] self-start" />
        <span className="text-neutral text-sm font-bold leading-tight">
          Reply from <span className="whitespace-nowrap">Virgin Experience Days</span>
        </span>
      </div>
      <BodyText className="text-neutral">{content}</BodyText>
    </div>
  );
};
