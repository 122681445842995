import { Image } from '../../layout/Image';
import { LinkWrap } from '../../navigation/LinkWrap';
import { VisualHeading } from '../../typography/VisualHeading';

type ProductStubProps = {
  imgUrl: string;
  linkUrl: string;
  imgAlt: string;
  name: string;
};

export const ProductStub = ({ imgUrl, imgAlt, name, linkUrl }: ProductStubProps) => {
  return (
    <LinkWrap className="mt-6 block no-underline" href={linkUrl}>
      <div className="flex items-center" data-testid="product-stub">
        <Image
          className="image:object-cover aspect-square basis-[80px] rounded"
          src={imgUrl}
          alt={imgAlt}
          fill
        />
        <div className="ml-4 line-clamp-3 flex-1 items-center font-semibold">
          <VisualHeading
            className="text-neutral-strong font-inter mr-2 text-sm font-semibold leading-normal tracking-normal sm:text-sm"
            size="4"
          >
            {name}
          </VisualHeading>
        </div>
      </div>
    </LinkWrap>
  );
};
