import { VisualHeading } from '../typography/VisualHeading';
import { SideDrawer } from '../layout/SideDrawer';

import { ReviewCard } from '../cards/Review';

import type { Review } from '@virginexperiencedays/products';
import { LinkWrap } from '../navigation/LinkWrap';

export const createDrawerTitle = () => (
  <div className="flex items-baseline">
    <VisualHeading
      className="text-neutral-strong mr-2 text-4xl leading-none sm:text-4xl"
      size="2"
      data-testid="customer-reviews-drawer-title"
    >
      Reviews
    </VisualHeading>
  </div>
);

export const DrawerContent = ({
  isOpen,
  toggleDrawer,
  reviews,
}: {
  isOpen: boolean;
  reviews: Review[];
  toggleDrawer: () => void;
}) => {
  if (!reviews?.length) return null;

  return (
    <SideDrawer
      isOpen={isOpen}
      drawerTitle={() => createDrawerTitle()}
      hideDrawer={toggleDrawer}
      headerAlign="shadow-sticky"
      allowEventDefault
    >
      {reviews.map(({ rating, dateCreated, title, content, reviewerName, reply }) => (
        <ReviewCard
          key={`${reviewerName}-${dateCreated}`}
          rating={rating}
          dateCreated={dateCreated}
          title={title}
          content={content}
          reviewerName={reviewerName}
          reply={reply}
        />
      ))}
      <div className="px-0 pb-8 pt-4 text-center">
        <LinkWrap
          href={`${process.env.NEXT_PUBLIC_HOME_LINK}/reviews`}
          className="text-link text-sm font-semibold leading-normal underline underline-offset-4"
          isExternal
        >
          Read reviews about all our experiences
        </LinkWrap>
      </div>
    </SideDrawer>
  );
};
