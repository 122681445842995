// Components
import { DisplayHeading } from '@virginexperiencedays/components-v2/src/typography/DisplayHeading';
import { NuggetModule } from '@virginexperiencedays/components-v2/src/navigation/NuggetModule';
import type { DisplayHeadingProps } from '@virginexperiencedays/components-v2/src/typography/DisplayHeading';
// Utils
import {
  getPrismicLinkPath,
  type PrismicLink,
} from '@virginexperiencedays/header-2024/src/utils/cms/getPrismicLinkPath';
// Types
import type { PrismicContext } from '../../types/context';

/**
 * This component is created to use is for BF and Xmas pages, only manually curated content, no integration or anything.
 */
type CustomNuggetsProps = {
  slice: {
    primary: {
      title: string;
      title_size: DisplayHeadingProps['size'];
      is_wrapped: boolean;
    };
    items: {
      name: string;
      href: PrismicLink;
    }[];
  };
  context: PrismicContext;
};

const CustomNuggets = ({ slice, context }: CustomNuggetsProps) => {
  const { primary } = slice;
  const { hide, isFaceted } = context;

  if (!slice.items?.length || isFaceted || hide) {
    return null;
  }

  return (
    <div>
      {primary.title && (
        <DisplayHeading
          className="text-neutral-strong mb:4 text-xl font-bold leading-tight md:mb-6"
          size={primary.title_size ?? '2'}
        >
          {primary.title}
        </DisplayHeading>
      )}
      <NuggetModule
        navigationItems={slice.items
          .filter(({ name, href }) => name && href && getPrismicLinkPath(href))
          .map(({ name, href }) => ({
            name,
            href: getPrismicLinkPath(href),
            isHrefExternal: true,
          }))}
        isWrapped={primary.is_wrapped}
      />
    </div>
  );
};

export default CustomNuggets;
