import type { SliceZoneProps } from '@prismicio/react';

import { components } from '../slicemachine';

const commonComponents: (keyof typeof components)[] = [
  'accordion_group',
  'button',
  'carousel_structure',
  'carousel_with_intro_slot',
  'carousel_with_tabs',
  'chunky_message',
  'column_structure',
  'corporate_lead_form',
  'faq_accordion_group',
  'featured_product_grid',
  'featured_visual_cards',
  'gift_card_price_selector',
  'gift_card_slider',
  'gift_card_usp',
  'grid_structure',
  'hero_style01',
  'hero_style02',
  'hero_style03',
  'hero_style04',
  'hero_style05',
  'html_embed',
  'icon_banners',
  'image_row',
  'image_slice',
  'list_of_links',
  'mega_nuggets',
  'newsletter',
  'nugget',
  'promo_banner',
  'rich_text',
  'spacer',
  'title',
  'usp_grid',
];

const pageComponents = {
  /**
   * - custom_site_page (e.g. for business, gift finder)
   * - home_page
   * - home_page_repeatable
   * - loyalty_landing_page
   */
  default: commonComponents,

  /**
   * - category_listing_page
   * - category_override_listing_page
   * - location_listing_page
   * - price_range_listing_page
   * - rating_listing_page
   */
  clp: [...commonComponents, 'category_reviews', 'product_grid'],
};

export const getPageComponents = (
  key: keyof typeof pageComponents = 'default'
): SliceZoneProps['components'] => {
  const componentKeys = key in pageComponents ? pageComponents[key] : pageComponents.default;

  const validComponents = componentKeys.filter((k) => {
    if (!components?.[k]) {
      console.warn(`Component "${k}" not found in slice machine components`);
      return false;
    }
    return true;
  });

  return Object.fromEntries(validComponents.map((k) => [k, components[k]]));
};
