// Components
import { LinkWrap } from '@virginexperiencedays/components-v2/src/navigation/LinkWrap';
import { DisplayHeading } from '@virginexperiencedays/components-v2/src/typography/DisplayHeading';
// Types
import type { PrismicContext } from '../../types/context';

type BannerProps = {
  slice: {
    primary: {
      title: string;
      button_text: string;
      button_link: {
        url: string;
      };
    };
  };
  context: PrismicContext;
};

const Banner = ({ slice, context }: BannerProps) => {
  const { primary } = slice;
  const { hide, isFaceted } = context;

  if (isFaceted || hide || !primary?.title) {
    return null;
  }

  const shouldRenderButton = !!primary?.button_link?.url && !!primary?.button_text;

  return (
    <div className="bg-background-primary-faded flex w-full flex-col items-center justify-between sm:flex-row">
      <DisplayHeading className="m-10" size="3">
        {primary.title}
      </DisplayHeading>
      {shouldRenderButton && (
        <LinkWrap
          className="text-neutral m-10 rounded bg-white px-8 py-4 no-underline"
          href={primary.button_link.url}
        >
          {primary.button_text}
        </LinkWrap>
      )}
    </div>
  );
};

export default Banner;
